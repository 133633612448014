.chevron-button {
  background-color: white;
  color: black;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
}

.chevron-button:hover {
  background-color: white;
  color: black;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
}

.company-dropdown-menu {
  transform: translateX(-13%);
}

.mantine-Menu-itemLabel {
  font-family: "Helvetica";
  font-size: 16px !important;
}
.navbar-org-name {
  display: flex;
  margin-left: -5px;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  /* do not break next line*/
  white-space: nowrap;
  /* take full space */
}

.navbar-org-icon {
  margin-right: 0px;
  font-size: 17px;
  width: 32px !important;
  height: 32px !important;
}
