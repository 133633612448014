.already-have-account {
  width: 100%;
  height: 18px;
  margin: 30px 125px 30px 0;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.sign-in-link {
  color: #00d28f;
}

.label {
  margin: 30px 37px 15px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.verify-email {
  font-family: Helvetica;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

.verify-email-container {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.verify-box {
  width: 70px;
  height: 80px;
  margin: 0 10px 0 10px;
  font-size: 30px;
  text-align: center;
  border-radius: 10px;
  border: solid 2px #707070;
}

.verify-box-container {
  display: flex;
}

.verify-email-question {
  width: 166px;
  height: 17px;
  margin: 40.4px 40px 4.9px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  color: #707070;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
}

.user-email {
  width: 225px;
  height: 22px;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  color: black;
  line-height: 1.28;
  letter-spacing: normal;
}

.verify-email-answer {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.verify-email-yours-sincerely {
  font-family: Helvetica;
  font-size: 20px;
  color: black;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
}

.verify-email-link {
  color: #00d28f;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  margin: 12rem;
  margin-top: 6rem;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    width: 100% !important;
  }
}

.what-we-do-title {
  margin-top: 3.5rem;
  font-family: Helvetica;
  font-size: 40px;
  color: black;
  font-weight: bold;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
}

.get-started-btn {
  cursor: pointer;
}

.margin-top-0 {
  margin-top: 0rem;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-number-desc {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.card-padding {
  padding-bottom: 6rem;
}

.margin-top-6 {
  margin-top: 6rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-2 {
  margin-top: 2rem;
}
.onboarding .input {
  margin: 0px;
  padding: 0px;
  height: fit-content;
  font-size: 20px;
}

.onboarding .register-form-title {
  margin-left: 0;
}

.skip-button {
  margin: 20px 182px 0 181px;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  background-color: #fafafa;
  border: 0px;
  text-decoration: underline;
}

.select-field {
  font-size: 20px;
  font-family: Helvetica;
}

.select-field > div {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #707070 !important;
  border-radius: 0px !important;
}
.select-field > div > div {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.select-field > div > div > .css-14el2xx-placeholder {
  font-weight: 700;
  color: #cbcbcb !important;
}

.select-field > div > div > .css-1b9hq3t-Input {
  font-weight: 700;
  color: red;
}

div#react-select-3-listbox {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  font-family: Helvetica;
}

.react-tel-input .flag-dropdown {
  font-weight: 100 !important;
  /* font-size: 50px; */
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0px !important;
}

.flag {
  transform: scale(1.5) !important;
  margin-right: 12px !important;
}

.react-tel-input .form-control {
  /* font-size: 50px; */
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0px !important;
  width: 100% !important;
  font-size: 20px !important;
  font-family: Helvetica !important;
}

.react-tel-input .form-control::placeholder {
  color: #cbcbcb !important;
}
.react-tel-input .country-list {
  width: 330px !important;
}

.max-width-24 {
  max-width: 24rem;
}

.congratulations-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.congratulations-image {
  width: 177px;
  height: 177px;
  margin: 70px 0px 20px 0px;
  object-fit: contain;
}

.congratulations-title {
  margin: 20px 0px 40px 0px;
  font-family: Helvetica;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

.congratulations-content {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

.card-outer-container {
  background-color: #fafafa;
  /* height: 99.5%; */
}

.card-inner-container {
  background-color: white;
}

.verify-email-card {
  max-width: 29rem;
}

.left-chevron {
  color: #cbcbcb;
  font-size: 50px;
  position: absolute;
  top: 70px;
  left: 70px;
  cursor: pointer;
}

.right-chevron {
  color: #cbcbcb;
  font-size: 50px;
  position: absolute;
  top: 70px;
  right: 70px;
  cursor: pointer;
}

.estimated-expense-container {
  margin-top: 2.5rem;
}

.card-image {
  height: 90px;
  width: 85px;
}

.card-image.sync-accounting {
  width: 182px;
  height: 65px;
}

.switch-new-company {
  margin-top: 3rem;
}

@media (max-width: 2500px) {
  .max-width-24 {
    width: 24rem;
  }
}

@media (max-width: 500px) {
  .verify-box {
    width: 60px;
    height: 70px;
  }
  .register-form-title {
    font-size: 23px !important;
  }

  .what-we-do-title {
    font-size: 30px;
  }
  .sub-title {
    font-size: 20px;
  }
  .left-chevron {
    left: 5px;
    top: 5px;
    font-size: 25px;
  }

  .what-we-do-title {
    font-size: 26px !important;
  }

  .sub-title {
    font-size: 20px !important;
  }
  .max-width-24 {
    width: fit-content;
  }
}

@media (max-width: 400px) {
  .verify-box {
    width: 50px;
    height: 60px;
  }
}

@media (max-width: 300px) {
  .verify-box {
    width: 40px;
    height: 60px;
  }
}

@media screen and (max-width: 1600px) {
  .verify-email-image {
    height: 10rem;
  }

  .verify-box {
    width: 65px;
    height: 75px;
  }

  .verify-email-question {
    margin-top: 30px;
  }

  .card-container {
    margin: 2rem;
  }

  .what-we-do-title {
    font-size: 30px;
  }

  .card-style {
    width: 250px !important;
    height: 190px !important;
    margin: 15px 15px 15px 15px !important;
  }

  .sub-title {
    margin: 10px 0 0px !important;
    font-size: 20px !important;
  }

  .card-padding {
    padding-bottom: 4.5rem;
  }
  .button-image.margin-top-6 {
    margin-top: 2rem;
  }
  .progress-bar-container {
    margin-bottom: 2rem !important;
  }
  .estimated-expense-container {
    margin-top: 0rem;
  }
  .card-title {
    font-size: 20px !important;
  }

  .card-image {
    height: 80px;
    width: 77px;
  }

  .card-image.sync-accounting {
    width: 180px;
    height: 65px;
  }
  .switch-company-card {
    min-height: 35rem !important;
  }
  .card-description {
    margin-top: 2px;
  }

  .switch-new-company {
    margin-top: 1rem;
  }
  .congratulations-title {
    margin-bottom: 5px;
  }
  .congratulations-image {
    margin-top: 60px;
  }
  .congratulations-content {
    font-size: 16px;
  }
}

@media screen and (min-width: 1600px) {
  .card-outer-container {
    min-height: 100vh;
  }
}

.react-tel-input .form-control {
  font-size: 1rem !important;
  border-bottom: 1px solid #707070 !important;
  height: 39.80px !important;
}

.xero-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}