.password-updated {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: black;
}
