.financial-health-link {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  text-decoration: underline;
  color: black;
}

.financial-health-link:hover {
  color: black;
}

.legend-container {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
}

.company-dashboard-container {
  margin-left: 6%;
  margin-right: 6%;
}

.graph-card {
  margin: 0.7rem;
  flex: 1 1 auto;
}

.hero-body-companies {
  height: 100vh;
  overflow-y: scroll;
}

.runway-switch-contantainer {
  display: flex;
  justify-content: space-between;
}

.runway-switch {
  margin-top: -0.25rem;
}

.graph-card .donut {
  flex: 1 1 auto;
}
.donut {
  height: 2vh;
  width: 25vw;
}
.donut canvas {
  width: 85% !important;
  margin-top: -2rem;
  height: 112% !important;
  flex: 1 1 auto;
}

.financial-health-image {
  width: 147px;
  height: 150px;
}

.line-charts-container {
  display: flex;
  flex-direction: column;
  width: 55%;
  max-width: 55%;
}

.bar-charts-container {
  display: flex;
  flex-direction: column;
  max-width: 18vw;
}

.runway-financial-container {
  display: flex;
  flex-direction: column;
  max-width: 12.5vw;
  height: 100%;
  width: 100%;
}

.bar-runway-container {
  display: flex;
  height: 20%;
  width: 100%;
}

.right-outer-container {
  display: flex;
  flex-direction: column;
  max-width: 45%;
}

.switch-company-card {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  min-height: 38rem;
  margin-top: -1.5rem;
}

.mantine-font-text {
  font-size: 20px !important;
  font-family: "Helvetica";
}

.mantine-Select-input {
  border: none !important;
  border-bottom: 1px solid #707070 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  font-size: 24px;
  font-family: Helvetica;
  outline: none !important;
}

.financial-health-container {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.financial-health-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35%;
}

.companies-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.companies-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 425px;
}

.zoho-logo {
  width: 120px;
  height: 120px;
}

.link-button {
  text-decoration: underline;
  color: #707070;
  margin-top: 10px;
  margin-bottom: 15px;
}

@media screen and (max-width: 1350px) {
  .mantine-Select-input {
    font-size: 20px;
  }
}
