.form-container {
  margin-top: 5px;
  display: flex;
}

.source-label {
  margin-left: 5px;
}

#drop-down-item:hover {
  text-decoration: none;
  background: white;
  color: #575757;
}

#drop-down-item-cashout:hover {
  text-decoration: none;
  background: white;
  color: #575757;
}
.balance-container {
  overflow-y: auto;
  height: 500px;
}

.balance-table {
  border-collapse: separate;
  border-spacing: 70px 25px;
}

.balance-input {
  border: none;
  background: transparent;
  border-bottom: 1px solid #575757;
  outline: none;
}

/* input[type="radio"] {
  appearance: none;
  width: 12px;
  height: 12px;
  border: 1px solid #555555;
  border-radius: 50%;
  background-clip: content-box;
}

input[type="radio"]:checked {
  padding: 1.5px;
  background-color: #00d28f;
} */

.bank-img {
  height: 15px;
  width: 15px;
}

.cash-img {
  height: 20px;
  width: 20px;
}
.main-item-cashflow {
  font-weight: bold;
  color: black;
}
.source-container {
  margin-top: 5px;
}

.expandable-table-row {
  height: 40px;
  border: none;
  font-size: 12px;
  display: data-cell;
  vertical-align: middle;
}
.net-cashflow-row {
  height: 40px;
}

.cashflow-filters {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.submenu-content {
  margin-left: 5px;
}

.cashflow-tutorial-img {
  margin: 1.5rem;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 0.5rem;
}

.cashflow-tutorial-subtitle {
  font-family: Helvetica;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
}

.cashflow-tutorial .mantine-Modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cashflow-tutorial .mantine-Modal-title {
  font-family: Helvetica;
  font-size: 40px;
  margin: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
}

.cashflow-tutorial .mantine-Group-root > button {
  width: 78%;
  color: white;
  font-size: 19px;
  height: 43px;
}

.cashflow-tutorial .mantine-Modal-modal {
  border-radius: 25px;
}

.tutorial-popover-subtitle {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 500;
  align-self: flex-start;

  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

.popover-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutorial-popover {
  padding: 1.2rem 1rem 1.2rem 2rem !important;
  border-radius: 15px !important;
  z-index: 100;
}

.tutorial-popover .rs-popover-title {
  color: black;
  font-size: 16px;
}
.tutorial-btn-container {
  background-color: white;
  padding: 0.5rem;
  border-radius: 7px;
  position: relative;
  z-index: 9;
}

.rs-dropdown-toggle.rs-btn {
  z-index: 4 !important;
}

.tutorial-popover-img {
  margin-top: 1rem;
  margin-left: -1rem;
  margin-bottom: 0.8rem;
  /* margin-right: -1rem; */
}
@media screen and (max-width: 1350px) {
  .cashflow-filters {
    margin-right: 2rem;
  }
}

@media screen and (min-width: 1350px) {
  .cashflow-filters {
    margin-right: 2rem;
  }
}

.cashflow-page {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.cashflow-page::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.trial-welcome-modal-text {
  margin-top: 10px;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
}

.trial-welcome-modal-body {
  padding: 0px 10px;
}

.config-header-logo-style {
  align-items: flex-end !important;
  justify-content: space-between !important;
  gap: 20px !important;
}