.label {
  margin: 30px 37px 15px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.input {
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  border-bottom: 1px solid #707070;
  border-radius: 0px;
}

.input:focus {
  border-bottom: 1px solid #707070;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-top: 1.4rem;
  margin-bottom: 3rem;
}

.logo {
  width: 275px;
  height: 90px;

  @media screen and (max-height: 650px) {
    width: 225px;
    height: 80px;
    margin-top: 5rem;
  }
}

.register-form-title {
  height: 36px;
  margin-left: 1rem;
  font-family: Helvetica;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.button-image {
  background-color: #00d28f;
  width: 100%;
  max-width: 23rem;
  height: 50px;
  color: white;
  border-radius: 10px;
}

.button-image .no-border {
  border: none;
}
.button-image span {
  font-size: 1.4rem;
  font-family: Helvetica;
}

.button-image:disabled {
  background-color: #cbcbcb;
}
