.skip-button {
  font-family: Helvetica;
  text-align: center;
}

.skip-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skip-button-container button {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px !important;
  font-size: 15px;
}

