.collapsed-sidebar-item-link {
  width: 86px;
  height: 80px;
}
.sidebar-image {
  height: 26px;
  width: 26px;
}

.tutorials-container-sidebar {
  background-color: white;
  z-index: 7;
  position: relative;
  border-radius: 6px;
  padding-top: 9px;
}

@media screen and (max-width: 1600px) {
  .collapsed-sidebar-item-link {
    width: 86px;
    height: 68px;
  }
  .sidebar-image {
    height: 26px;
    width: 26px;
  }
}
