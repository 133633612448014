ul {
  list-style: revert;
  margin: auto;
  padding: revert;
}

li {
  margin: auto;
  padding: revert;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.section-title {
  font-size: 24px;
}

.privacy-policy-container {
  margin-left: 21%;
  margin-right: 19%;
}

.main-heading {
  text-align: center;
  margin: 3rem;
}

p {
  font-family: "Poppins";
}

.section-title {
  font-family: "Poppins";
  margin-top: 1rem;
}

.section-sub-title {
  font-size: 22px;
}

.privacy-table-row {
  border: 1px solid black;
  padding: 0.5rem;
}

.privacy-table-column {
  padding: 0.5rem;
  border: 1px solid black;
}

.privacy-policy-table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.privacy-cookie {
  margin-top: 0.5rem;
}
