.edit-profile-column {
  padding-bottom: 0rem !important;
  padding-left: 0.75rem;
}

.column {
  padding: 0rem;
  padding-left: 0.75rem;
}
.profile-details-card-container {
  padding: 30px 32px !important;
  margin-bottom: 15px;
  position: relative;
}
.profile-picture-card-container {
  padding: 30px 32px !important;
  margin-bottom: 15px;
}

@media screen and (max-width: 1600px) {
  .profile-details-card-container {
    padding: 20px 26px !important;
  }
  .profile-picture-card-container {
    padding: 20px 26px !important;
    margin-bottom: 15px;
  }
}
