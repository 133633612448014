.open-balance-title {
  font-size: 24px;
  font-weight: 600;
}
.open-balance-value {
  font-size: 24px;
  font-weight: 700;
}
.button.is-small {
  width: 132px;
}

.date-amount-container {
  margin-top: 1.5rem;
}

.details-card-container {
  padding: 30px 32px !important;
  position: relative;
  margin-bottom: 90px;
}

.details-card-container.contacts {
  padding: 30px 32px !important;
  position: relative;
  margin-bottom: 60px;
}
.add-record-card-container {
  padding: 25;
  margin: 0;
  border-radius: 10;

  width: 55%;
}

.edit-payable .add-record-card-container {
  width: 50%;
}
.manual-bill-container {
  padding: 25px !important;
  width: 30%;
  margin: 0;
  border-radius: 10;
}
.review-pay-card {
  min-width: 40%;
  padding: 25;
  margin-top: 30px;
}

.details-card-container.runway {
  padding-bottom: 0px !important;
}
.subtitle {
  font-size: 22px;
}
.card-date {
  font-size: 14px;
  font-weight: 600;
}
.card-code {
  font-size: 14px;
}
.detail-subheading-code {
  font-size: 10px;
  font-weight: 600;
}
.button.edit-cashflow {
  top: 20px !important;
}

.overdue-status {
  color: #c12e5b;
  margin-top: -1px;
  height: 31px;
  padding: 0px 12px 0px 14px;
  border-radius: 5px;
  border: solid 1px #c12e5b;
  background-color: var(--white);
}

.overdue-status.orange {
  color: rgb(241, 136, 63);
  border: solid 1px rgb(241, 136, 63);
}

.button-invoice {
  margin: 0.25rem;
  border-radius: 8px;
}

.button-invoice-edit {
  margin: 0.25rem;
  border-radius: 8px;
  background-color: #faded4;
  color: black;
}

.buttons-invoice-container {
  display: flex;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.no-edit-currency {
  color: black;
  margin: 1rem;
}
@media screen and (max-width: 1600px) {
  .subtitle {
    font-size: 17px;
  }
  .sort-icon-button img {
    height: 1.3rem !important;
  }
  .filter-icon-button img {
    height: 1.7rem !important;
  }

  .open-balance-title {
    font-size: 20px;
  }
  .open-balance-value {
    font-size: 20px;
  }

  .button.is-small {
    width: 100px;
  }

  .button.edit-cashflow {
    top: 23px !important;
  }

  .date-amount-container {
    margin-top: 1rem;
  }

  .details-card-container {
    padding: 20px 26px !important;
    position: relative;
    margin-bottom: 40px;
  }
  .details-card-container.contacts {
    padding: 20px 26px !important;
    position: relative;
    margin-bottom: 20px;
  }
  .details-card-container.runway {
    padding-bottom: 0px !important;
  }
  .add-record-card-container {
  }
  .manual-bill-container {
    width: 45%;
  }
  .review-pay-card {
    margin-top: 5px;
  }
  .card-date {
    font-size: 12px;
    font-weight: 600;
  }
  .card-code {
    font-size: 12px;
  }
  .detail-subheading-code {
    font-size: 13px;
  }
  /* For mobile phones: */
}
