.card-style {
  display: flex;
  background-color: white;
  width: 260px;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin: 20px 20px 20px 20px;
  padding: 30px 12px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 #707070;
  border: solid 1px white;
  background-color: whit;
  flex-direction: column;

  @media (max-width: 480px) {
    gap: 5px;
  }
}

.card-title {
  height: 28px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: black;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
}

.card-description {
  font-family: Helvetica;
  font-size: 14px;
  padding: 0px;
  font-weight: normal;
  font-stretch: normal;
  color: #707070;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
}

.sub-title {
  margin: 30px 0 62px;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

.no-shadow {
  box-shadow: none;
  background-color: #fafafa;
}

.selected-type {
  border: 2px solid black;
}

.hover-card:hover {
  box-shadow: 0 7px 9px 0 #555555;
  cursor: pointer;
}
