.sort-icon-button:active {
  background-color: #fafafa !important;
}

.sort-icon-button:focus {
  background-color: #fafafa !important;
}

.sort-icon-button:active {
  background-color: #fafafa !important;
}
.sort-icon-button {
  margin-top: 0.8rem;
  padding: 0px !important;
  margin-left: 0.4rem;
  margin-right: 0.5rem;
}

.sort-icon-button img {
  height: 1.5rem;
}

.filter-icon-button img {
  height: 2rem;
}

.search-icon-button {
  height: 1.5rem !important;
}
