.review-and-confirm-container {
  width: 40%;
}

@media screen and (max-width: 1600px) {
  .form-edit-field {
    font-size: 20px !important;
  }
  .review-and-confirm-container {
    width: 65%;
  }
}
