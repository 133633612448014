.left-chevron {
  color: #cbcbcb;
  font-size: 50px;
  position: absolute;
  top: 70px;
  left: 70px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .left-chevron {
    left: 5px;
    top: 5px;
    font-size: 25px;
  }
}
