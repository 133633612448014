.page-layout-right {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

@media screen and (max-width: 1600px) {
  .page-layout-right {
    padding-left: 1rem !important;
    padding-right: 2rem !important;
  }
}
