.list-container {
  padding: 3rem;
  margin-right: 3rem;
}

.list-item {
  margin-right: 3rem !important;
  padding: 1rem;
}

.label-edit-value {
  font-size: 24px;
  word-wrap: break-word;
}

.label-value-mb {
  margin-bottom: 1.5rem;
}

.open-balance-container {
  height: 90px;
  background-color: #faded4;
  width: 100%;
  border-bottom-left-radius: 15;
  border-bottom-right-radius: 15;
  align-items: center;
  padding: 0px 50px 0px 35px;
  border-top: 1px solid #888;
  position: absolute;
  bottom: -60;
  right: 0;
}
.get-started-container {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
  margin-top: 3rem;
}
.get-started-cards-container {
  margin-top: 3rem;
}
.get-started-card {
  width: 48%;
}
.list-container-get-paid {
  margin-right: 0.5rem;
  padding: 3rem;
  margin-right: 3rem;
}
.edit-cashflow {
  top: 26px !important;
}
.label-value-mb {
  margin-bottom: 20px;
}

.title.is-4 {
  font-size: 26px;
}

.title-span {
  padding: 8px;
  padding-left: 0px;
}

.page-title {
  font-size: 40px;
}
.top-header {
  margin: 1.5rem;
  margin-bottom: -0.3rem;
  margin-top: 0.7rem;
}

.data-active {
  color: black;
  font-weight: 700;
  padding: "18px 16px";
  border-bottom: 2px solid black;
}

.payment-contact-container {
  padding: 15px 100px 15px 20px;
  border: solid 1px #efefef;
  background-color: white;
  margin: 30px 0 25px;
}
.payment-contact {
  font-weight: bold;
  font-size: 20px;
  color: #555555;
}

.payment-contact-key {
  font-size: 13px;
  font-weight: bold;
  color: #707070;
}

.payment-contact-value {
  font-size: 18px;
  color: #1d1d1d;
}

.edit-payment-input {
  width: 100%;
}
@media screen and (max-width: 1600px) {
  .list-container {
    padding: 2rem;
  }
  .list-item {
    margin-right: 2rem !important;
    padding: 1rem;
  }
  .title.is-4 {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .label-edit-value {
    font-size: 20px !important;
  }
  .label-value-mb {
    margin-bottom: 1rem;
  }

  .edit-cashflow {
    top: 16px !important;
  }
  .open-balance-container {
    padding: 0px 25px 0px 28px !important;
    height: 70px !important;
  }

  .get-started-container {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    margin-top: 1rem;
  }
  .get-started-cards-container {
    margin-top: 1.5rem;
  }
  .get-started-card {
    width: 49%;
  }
  .list-container-get-paid {
    margin-right: 0.5rem;
    padding: 1rem;
  }

  .page-title {
    padding-top: 6px;
    font-size: 30px;
  }
}

.edit-label-title {
  font-size: 14;
  font-weight: 600;
  color: #969696;
}
