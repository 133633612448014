.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-primary {
  color: white !important;
  font-size: 15px !important;
  padding-left: 2rem;
  padding-right: 2rem;
}
