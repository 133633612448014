.card-subtitle-heading {
  margin: 1px;

  margin-bottom: 0.5rem;
  margin-left: 0px;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: black;

  @media screen and (max-width: 1650px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1500px) {
    font-size: 14px;
  }
}
