body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Hind", sans-serif;
  height: 100%;
  letter-spacing: 0.05em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/Hind-Regular.ttf") format("ttf"),
    url("./assets/fonts/Hind-Bold.ttf") format("ttf");
  font-weight: bold;
  font-display: swap;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  /* font-family: "Helvetica 25 UltraLight", sans-serif;
  font-family: "Helvetica 35 Thin", sans-serif;
  font-family: "Helvetica 45 Light", sans-serif;
  font-family: "Helvetica 55 Roman", sans-serif;
  font-family: "Helvetica 65 Medium", sans-serif; */

  font-weight: 400;
}

.nav.is-dark {
  background-color: #232b2d;
  color: #f6f7f7;
}

.nav.is-dark .nav-item a,
.nav.is-dark a.nav-item {
  color: #f6f7f7;
}

.nav.is-dark .nav-item a.button.is-default {
  color: #f6f7f7;
  background-color: transparent;
  border-width: 2px;
}

.nav.menu {
  border-bottom: 1px solid #e1e1e1;
}

.nav.menu .nav-item .icon-btn {
  border: 3px solid #b7c6c9;
  border-radius: 90px;
  padding: 5px 7px;
  color: #b7c6c9;
}

.nav.menu .nav-item.is-active .icon-btn {
  color: #2eb398;
  border: 3px solid #2eb398;
}

.nav.menu .nav-item .icon-btn .fa {
  font-size: 20px;
  color: #b7c6c9;
}

.nav.menu .nav-item.is-active .icon-btn .fa {
  color: #2eb398;
}

.aside {
  background-color: #fdfeff;
  border-right: 1px solid #dedede;
}

.messages {
  display: block;
  background-color: #fff;
  border-right: 1px solid #dedede;
  padding: 40px 20px;
}

.message {
  display: block;
  background-color: #fff;
  padding: 40px 20px;
}

.aside .compose {
  height: 95px;
  margin: 0 -10px;
  padding: 45px 0px 30px;
}

.aside .compose .button .compose {
  font-size: 14px;
  font-weight: 700;
}

.aside .main {
  padding-right: 80px;
  padding-left: 40px;
  color: #6f7b7e;
}

.aside .title {
  color: #6f7b7e;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.aside .main .item {
  padding: 16px 0px;
  color: #6f7b7e;
  display: flex;
  align-items: center;
}

.aside .main .item:hover {
  background-color: #55525225;
}

.aside .main .icon {
  font-size: 19px;
  padding-right: 10px;
  color: #000000;
}

/* .aside .main .icon:hover {
  color: red;
  transition: 0.9s;
  transform: translateX("12px");
} */

.aside .main .name {
  font-size: 15px;
  color: #f7f2f2;
  font-weight: 500;
}

.messages .action-buttons {
  padding: 0;
  margin-top: -20px;
}

.message .action-buttons {
  padding: 0;
  margin-top: -5px;
}

.action-buttons .control.is-grouped {
  display: inline-block;
  margin-right: 30px;
}

.action-buttons .control.is-grouped:last-child {
  margin-right: 0;
}

.action-buttons .control.is-grouped .button:first-child {
  border-radius: 5px 0 0 5px;
}

.action-buttons .control.is-grouped .button:last-child {
  border-radius: 0 5px 5px 0;
}

.action-buttons .control.is-grouped .button {
  margin-right: -5px;
  border-radius: 0;
}

.pg {
  display: inline-block;
  top: 10px;
}

.action-buttons .pg .title {
  display: block;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 3px;
  font-size: 12px;
  color: #aaa;
}

.action-buttons .pg a {
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: none;
}

.is-grouped .button {
  background-image: linear-gradient(#f8f8f8, #f1f1f1);
}

.is-grouped .button .fa {
  font-size: 15px;
  color: #aaaaaa;
}

.inbox-messages {
  margin-top: 60px;
}

.message-preview {
  margin-top: 60px;
}

.inbox-messages .card {
  width: 100%;
}

.inbox-messages strong {
  color: #5d5d5d;
}

.inbox-messages .msg-check {
  padding: 0 20px;
}

.inbox-messages .msg-subject {
  padding: 10px 0;
  color: #5d5d5d;
}

.inbox-messages .msg-attachment {
  float: right;
}

.inbox-messages .msg-snippet {
  padding: 5px 20px 0px 5px;
}

.inbox-messages .msg-subject .fa {
  font-size: 14px;
  padding: 3px 0;
}

.inbox-messages .msg-timestamp {
  float: right;
  padding: 0 20px;
  color: #5d5d5d;
}

.message-preview .avatar {
  display: inline-block;
}

.message-preview .top .address {
  display: inline-block;
  padding: 0 20px;
}

.avatar img {
  width: 40px;
  border-radius: 50px;
  border: 2px solid #999;
  padding: 2px;
}

.address .name {
  font-size: 16px;
  font-weight: bold;
}

.address .email {
  font-weight: bold;
  color: #b6c7d1;
}

.card.active {
  background-color: #f5f5f5;
}

.mybtn {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 6px 8px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 8px;
}

/* Primary */
.primary {
  border-color: #001736;
  color: #001736;
}

.primary:hover {
  background-color: #001736;
  color: white;
}

.dashboard-btn {
  background-color: #2b3f63dc;
  padding: 15px;
  color: white;
  border-radius: 2px;
}

.dashboard-btn :hover {
  color: white;
}

/* .bottom-arrow:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 5px solid #6a0136;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
} */

.page-main-title {
  width: 367px;
  height: 44px;
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  /* identical to box height, or 129% */

  text-transform: capitalize;

  color: #3a4132;
}

.flex {
  display: flex;
}

.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.flex-row-end {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-direction-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-direction-row-space-between-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.align-center {
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-layout-animated-border {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, black 50%, transparent 50%),
    linear-gradient(90deg, black 50%, transparent 50%),
    linear-gradient(0deg, black 50%, transparent 50%),
    linear-gradient(0deg, black 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 8px 1px, 8px 1px, 1px 8px, 1px 8px;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
  border-radius: 5px;
  animation: dash 30s linear infinite;
}

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}

.is-bold {
  font-weight: 700;
}

.is-upper-case {
  text-transform: uppercase;
}

/* .custom-scrollbar {
  overflow-y: "scroll";
} */

.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
  /* width of the entire scrollbar */
  height: 7px;
}

.custom-scrollbar-is-thin::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(228, 221, 221, 0.562);
  /* color of the tracking area */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.517);
  /* color of the scroll thumb */
  border-radius: 15px;
  cursor: pointer;
}

.custom-scrollbar-cashflow::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
  height: 9px;
  cursor: pointer;
}

.custom-scrollbar-cashflow::-webkit-scrollbar-track {
  background: rgba(228, 221, 221, 0.562);
  /* color of the tracking area */
}

.custom-scrollbar-cashflow::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.517);
  /* color of the scroll thumb */
  border-radius: 20px;
}

/*
  write media query to give margin on the sides on bigger screens
*/
/* @media screen and (max-width: 768px) {
  .main {
    margin: 0 100px;
  }
} */
@media screen and (max-width: 1350px) {
  .filters-tabs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1350) and (max-width: 1600px) {
  .filters-tabs {
    flex-direction: columPn;
    justify-content: center;
    align-items: center;
  }
}

.is-gap-1 {
  gap: 1rem;
}

.is-gap-2 {
  gap: 2rem;
}

.is-gap-3 {
  gap: 3rem;
}

.is-gap-4 {
  gap: 4rem;
}

.blurred-data {
  filter: blur(5px);
  user-select: none;
  pointer-events: none;
}