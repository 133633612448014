.currency-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

.currency-image {
  width: 100px;
  height: 100px;

}

.detail-container {
  margin-top: 25px;
}

.profile-image {
  margin-top: 10px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
}