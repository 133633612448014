.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-title {
  display: flex;
}

.graph-legend-container {
  display: "flex";
  flex-direction: "column";
}

.card-title-heading {
  margin-top: 0px;

  @media screen and (max-width: 1650px) {
    font-size: 12px;
  }

  @media screen and (max-width: 1500px) {
    font-size: 11px;
  }
}
