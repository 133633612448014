.forget-password-title {
  text-align: center;
  margin-top: 1rem;
  font-size: 20px;
  color: black;
  font-family: Helvetica;
}

.forget-password-content {
  text-align: center;
  font-size: 15px;
  font-family: Helvetica;
}
